import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "../components/style.css"

const AboutPage = () => (
  <Layout>
    <SEO title="Services - Local Joes Locksmith" />
    <div className="row">
      <div className="col-xs-12 col-lg-9">
        <h2>About Us</h2>
        <p>
          When it comes to picking a locksmith, you want someone you can trust.{" "}
          <strong>'s Locksmith</strong> is family owned and operated and brings
          you years of experience. We pride ourselves on our professionalism and
          our exceptional commitment to customer service and customer
          satisfaction.
        </p>
      </div>
      <div className="col-xs-12 col-lg-3 sidebar">
        <h3>Hours</h3>
        <div>
          <strong>Mon-Fri: </strong>
          <span>7am - 9pm</span>
        </div>
        <div>
          <strong>Sat-Sun: </strong>
          <span>7am - 9pm</span>
        </div>
      </div>
    </div>
  </Layout>
)

export default AboutPage
